$wrapper-width-lg: 1023px;

$initial-font-size: 18px;

$font-color-white: #fff;
$font-color-black: #000;
$font-color-grey: #6d748b;
$font-color-yellow: #ffc910;

$font-weight-regular: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$color-primary: #3b35e8;
$color-secondary: #ffc910;
$color-white: #fff;

$shadow-color-light: rgba(0, 0, 0, 0.1);

$button-border-radius: 5px;

$max-z-index: 999;

$screen-xl: 1600px;
$screen-l: 1200px;
$screen-m: 1000px;
$screen-s: 650px;
$screen-xs: 500px;

$spinner-color: rgba(65, 65, 65, 0.9);

$sidebar-width: 300px;
$sidebar-width-mobile: 250px;
$sidebar-background-color: #eff0f4;

$sidebar-mobile-header-background-color: #eff0f4;
$sidebar-mobile-header-height: 70px;

$sidebar-menu-item-color: #333;
$sidebar-menu-item-color-active: #39f;
$sidebar-menu-item-color-hover: #389fee;
$sidebar-menu-item-group-color-hover: #0178ca;

$docs-font-size: 17px;
$docs-font-size-mobile: 16px;

$docs-link-color: #39f;
$docs-link-color-hover: #0178ca;

$docs-badge-primary-background-color: #65aee8;
$docs-badge-primary-color: #ffffff;
$docs-badge-primary-border-color: #5ea9e8;

$docs-badge-secondary-background-color: #eeeff0;
$docs-badge-secondary-color: #333;
$docs-badge-secondary-border-color: #d9d9d9;

$docs-badge-warning-background-color: #ff7630;
$docs-badge-warning-color: #ffffff;
$docs-badge-warning-border-color: #fa501bff;

$docs-notice-info-background-color: #f7f7f7;
$docs-notice-info-color: #333;
$docs-notice-info-border-color: #5ea9e8;
$docs-notice-warn-border-color: #f58d0d;

$docs-segment-title-color: #39f;
$docs-segment-content-color-primary: #333;

$docs-tabs-color: #000;
$docs-tabs-color-active: #39f;
$docs-tabs-border-color: #d9d9d9;
$docs-tabs-border-color-active: #39f;
$docs-tabs-border-color-hover: #bebebe;

$docs-table-border-color: #d9d9d9;

$docs-modal-background-color: #ffffff;
$docs-modal-overlay-background-color: #3d424e;
