@import "../../../styles/variables";

.ServicesSegment {
  padding: 300px 0 150px;
  height: auto;
  margin-top: -7em;
  background-image: linear-gradient(to right, #3a7ce6, #3976e2, #323dbf, #3136bc);
  .services-list {
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    top: -11em;
    transform: skewY(-7deg);
    width: 100%;
    height: 19em;
    left: 0;
    background-color: #fff;
  }

  .middle-wave-bg-0 {
    z-index: 0;
    opacity: 0.1;
    position: absolute;
    width: 120%;
    bottom: -30%;
    left: -10%;
  }
}

@media screen and (max-width: $screen-xl) {
  .ServicesSegment {
    .middle-wave-bg-0 {
      bottom: -7%;
    }
  }
}

@media screen and (max-width: $screen-s) {
  .ServicesSegment {
    padding: 200px 0 50px;
    .middle-wave-bg-0 {
      bottom: -5%;
    }
  }
}
