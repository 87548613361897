@import "../../styles/variables";

.container {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: .5em 2em;
  box-sizing: border-box;
  background-color: $color-white;
  z-index: $max-z-index;
  color: $font-color-grey;
  overflow: hidden;
  transform: translateY(100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 20px 0 $shadow-color-light;

  &.shown {
    transform: translateY(0);
  }

  span {
    font-size: .9em;
    position: relative;
  }

  .hide-warning-button-container {
    position: relative;
    width: 95px;
    box-sizing: border-box;
    .button {
      padding: .45em .65em;
      width: 100%;
      background-color: transparent;
      box-sizing: border-box;
      border: 1px solid $font-color-grey;
      border-radius: $button-border-radius;
      color: $font-color-grey;
      font-size: .9em;

      &:hover {
        background-color: $font-color-grey;
        color: $font-color-white;
      }
    }
  }

  &.animated {
    transition: transform .15s ease;
  }
}

@media screen and (max-width: $screen-m) {
  .container {
    padding: 1em;
    span {
      font-size: .8em;
      padding-right: 1em;
      box-sizing: border-box;
    }
    .hide-warning-button-container {
      min-width: 65px;
      .button {
        padding: .45em;
        font-size: .85em;
      }
    }
  }
}

@media screen and (max-width: $screen-xs) {
  .container {
    padding: .5em;
    span {
      font-size: .75em;
    }
    .hide-warning-button-container {
      min-width: 55px;
      .button {
        padding: .5em;
        font-size: .7em;
      }
    }
  }
}