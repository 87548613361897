@import "../../../../styles/variables";

.MainLeftPart {
  height: 100%;
  display: flex;
  align-content: center;
  z-index: 2;
  .content {
    height: 100%;
    margin-top: 35%;
  }
  h1 {
    font-size: 3em;
    font-weight: $font-weight-bold;
    text-align: left;
  }
  p {
    margin-top: 2.5em;
    font-size: 1.1em;
    line-height: 1.44;
  }
  .button-holder {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 3em;
    button {
      text-align: center;
      border-radius: $button-border-radius;
      overflow: hidden;
      border: none;
      font-size: 1.1em;
      padding: 1em 2.25em;
      font-family: 'Titillium Web', sans-serif;
      transition: background-color .2s ease;
      &:hover {
        box-shadow: 0 0 18px rgba(17, 39, 117, 0.15);
      }
    }
    .btn-create-account {
      background-color: $color-primary;
      color: $font-color-white;
      &:hover {
        background-color: #4545ff;
      }
    }
    .btn-contact-sales {
      background-color: $color-secondary;
      color: $font-color-black;
      &:hover {
        background-color: #ffdf16;
      }
    }
  }

}
@media screen and (max-width: $screen-l) {
  .MainLeftPart {
    .content {
      margin-top: 45%;
    }
    h1 {
      font-size: 2.75em;
      text-align: center;
    }
    .button-holder {
      button {
        font-size: 1em;
        padding: .9em 1.5em;
      }
    }
  }
}

@media screen and (max-width: $screen-m) {
  .MainLeftPart {
    .content {
      margin-top: 25%;
    }
    h1 {
      font-size: 2.45em;
      text-align: center;
    }
    p {
      text-align: center;
    }
    .button-holder {
      button {
        font-size: 1.15em;
        padding: 1em 2em;
      }
    }
  }
}

@media screen and (max-width: $screen-s) {
  .MainLeftPart {
    .content {
      margin-top: 25%;
    }
    h1 {
      font-size: 2em;
    }
    p {
      text-align: center;
      margin-top: 1.75em;
    }
    .button-holder {
      button {
        font-size: 1em;
        padding: .8em 1.25em;
      }
    }
  }
}

@media screen and (max-width: $screen-xs) {
  .MainLeftPart {
    .content {
      margin-top: 25%;
    }
    .button-holder {
      button {
        font-size: .9em;
        padding: .65em .8em;
      }
    }
  }
}
