@import "../../../styles/variables";

.ContactFormSegment {
  padding-top: 200px;
  margin: 0;
  box-sizing: border-box;

  .contactForm {
    box-sizing: border-box;
    max-width: 800px;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 103px 0 rgba(0, 0, 0, 0.14);
    padding: 70px 0 20px;
  }

  h2 {
    font-size: 2em;
    text-align: center;
    display: block;
  }

  h4 {
    font-weight: $font-weight-regular;
    color: $font-color-grey;
    font-size: 1.2em;
    line-height: 1.371;
    margin: 15px 0;
    text-align: center;
    display: block;
  }

  .form {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
  }

  .input-holder {
    display: flex;
    margin: .25em 0;
    width: 100%;
    position: relative;
    padding: 1em 7.5% 1em 5%;
    box-sizing: border-box;

    label {
      text-align: right;
      width: 20%;
      margin-right: 30px;
      font-size: 1.1em;
      color: #6d748b;
      display: flex;
      justify-content: flex-end;
      padding-top: 15px;
      transition: .2s ease;
    }

    input, textarea {
      width: 80%;
      border-style: solid;
      border-width: 1px;
      border-color: #f1f0f0;
      border-radius: 5px;
      background-color: #f9f9fb;
      font-size: 1em;
      padding: .75em;
      color: #6d748b;
      box-sizing: border-box;
      transition: .2s ease;
      &:focus {
        outline: none;
        box-shadow: 0 0 3px 1px $color-primary;
      }
    }

    small {
      position: absolute;
      font-size: .9em;
      left: 26%;
      bottom: 1.5em;
      color: red;
      transition: bottom .2s ease;
      z-index: -1;
      opacity: 0;
    }

    textarea {
      min-height: 120px;
      padding: 0 10px;
      font-family: 'Titillium Web', sans-serif;
      display: flex;
      resize: vertical;
    }

    &.error {
      input, textarea {
        box-shadow: 0 0 2px 1px red;
        color: red;
      }

      small {
        bottom: -.25em;
        opacity: 1;
      }

      label {
        color: red;
      }
    }
  }

  .button-holder {
    display: block;
    padding: 0 7.5% 0 5%;
    margin: 30px 0;

    .submit-btn {
      margin-left: 23%;
      border-radius: 5px;
      background-color: rgb(59, 53, 232);
      padding: 1.25em 3em;
      box-sizing: border-box;
      color: $font-color-white;
      font-size: 1em;
      transition: background-color .2s ease;
      outline: none !important;

      &.disabled {
        background-color: rgb(196, 192, 202);
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1000px;
    transform: skewY(-12deg);
    background-color: #fafafa;
    left: 0;
    top: -50%;
    z-index: -1;
    overflow: hidden;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: -150%;
    left: 0;
    z-index: -2;
    background-image: linear-gradient(90deg, #3a7fe9, #2b2cb0);
    height: 250%
  }
}

@media screen and (max-width: $screen-m) {
  .ContactFormSegment {
    .contactForm {
      padding: 3em 1em;
    }

    h2 {
      font-size: 2em;
    }

    h4 {
      font-size: 1em;
      margin: 1em 0;
    }

    .input-holder {
      padding: .5em 0 1em;

      label {
        font-size: .9em;
        margin-right: 1em;
        padding-top: 0;
        margin-top: .75em;
      }

      input, textarea {
        padding: .5em;
        font-size: 1em;
      }

      small {
        font-size: .75em;
        bottom: 1.75em;
      }

      &.error {
        small {
          bottom: 0;
          left: 23%;
        }
      }
    }

    .button-holder {
      margin: 1.5em 0 0;
      padding: 0;

      .submit-btn {
        margin-left: 50%;
        transform: translateX(-60%);
        padding: 1em;
      }
    }

  }
}

@media screen and (max-width: $screen-s) {
  .ContactFormSegment {
    padding-top: 5em;

    .contactForm {
      padding: 1.5em 1em;
    }

    h2 {
      font-size: 1.75em;
    }

    h4 {
      font-size: .9em;
      margin: 1em 0;
    }

    .input-holder {
      margin: 2.25em 0;
      padding: 0;

      label {
        width: 100%;
        position: absolute;
        left: 5px;
        margin-top: -1.25em;
        justify-content: flex-start;
      }

      input, textarea {
        width: 100%;
        margin-right: initial;
        min-width: 100%;
      }

      textarea {
        min-height: 100px;
      }

      small {
        left: .25em !important;
        font-size: .65em;
        transform: translateX(0);
      }

      &.error {
        small {
          bottom: -1.5em;
        }
      }
    }

    .button-holder {
      margin: 0;

      .submit-btn {
        margin-left: 50%;
        transform: translateX(-50%);
        padding: .75em .5em;
        width: auto;
        font-size: .85em;
      }
    }

    &::before {
      height: 600px;
    }
  }
}

.text-success {
  color: #279e2a !important;
}

.text-error {
  color: #af2d00 !important;
}
