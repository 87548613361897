@import "../../../styles/variables";

.FeaturesSegment {
  padding-top: 7em;
  .bg-white {
    position: absolute;
    pointer-events: none;
    z-index: -1;
    width: 100%;
    left: 0;
    margin-top: -150px;
    img {
      width: 100%;
    }
  }
  .features-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  h2 {
    font-size: 3em;
    text-align: center;
    display: block;
  }
  h4 {
    font-weight: $font-weight-regular;
    color: $font-color-grey;
    font-size: 1.4em;
    line-height: 1.371;
    margin: 55px 80px;
    text-align: center;
    display: block;
  }
}

@media screen and (max-width: $screen-m) {
  .FeaturesSegment {
    margin: 4em 0;
    h4 {
      font-size: 1.2em;
      margin: 55px 0;
    }
  }
}
