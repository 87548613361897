@import "../../../../styles/variables";

.FeatureItemLayout {
  width: 30%;
  color: #000;
  padding: 5%;
  box-sizing: border-box;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.08);
  margin: 1.5em 0;
  cursor: default;
  background-color: white;
  h3 {
    font-size: 1.4em;
    font-weight: $font-weight-semi-bold;
    padding: 25px 0;
    box-sizing: border-box;
    cursor: default;
  }
  p {
    font-size: .9em;
    font-weight: $font-weight-semi-bold;
    line-height: 1.389;
    cursor: default;
    color: $font-color-grey;
  }
  &:hover {
    background-color: $color-secondary;
    p {
      color: $font-color-black;
    }
  }
}

@media screen and (max-width: $screen-l) {
  .FeatureItemLayout {
    padding: 5% 1em;
    h3 {
      text-align: center;
      font-size: 1.25em;
      padding: 10px 0;
    }
    p {
      font-size: .8em;
    }
  }
}

@media screen and (max-width: $screen-m) {
  .FeatureItemLayout {
    width: 45%;
    padding: 5% 1em;
    h3 {
      text-align: center;
      font-size: 1.25em;
    }
    p {
      font-size: .8em;
    }

    &:last-child {
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }
}

@media screen and (max-width: $screen-s) {
  .FeatureItemLayout {
    width: 100%;
    padding: 5%;
    h3 {
      text-align: center;
      font-size: 1.3em;
      padding: 5px 0 10px;
    }
    p {
      font-size: .85em;
    }
    &:last-child {
      margin-left: initial;
      transform: initial;
    }
  }
}

