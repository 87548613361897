@import "../../../../styles/variables";

.CompanySegmentItemLayout {
  width: 300px;
  padding: 1em;
  box-sizing: border-box;
  * {
    text-align: center;
  }
  div img {
    height: 85px;
  }
  h3 {
    font-size: 1.4em;
    font-weight: $font-weight-semi-bold;
    margin: 15px 0 25px;
  }
  p {
    font-size: 1em;
    font-weight: $font-weight-semi-bold;
    color: $font-color-grey;
    line-height: 1.389;
  }
}

@media screen and (max-width: $screen-m) {
  .CompanySegmentItemLayout {
    width: 450px;
    margin: 1em auto;
    padding: .25em;
    h3 {
      font-size: 1.1em;
    }
    p {
      font-size: .9em;
    }
  }
}

@media screen and (max-width: $screen-s) {
  .CompanySegmentItemLayout {
    width: 250px;
    margin: 1em auto;
    h3 {
      font-size: 1em;
    }
    p {
      font-size: .8em;
    }
  }
}
