.img {
  position: absolute;
}
.top-left-wave {
  @extend .img;
  opacity: .1;
  left: -10%;
  width: 100%;
  margin-top: -5%;
  z-index: 0;
}
.bg-blue-1 {
  @extend .img;
  width: 100%;
  z-index: -2;
  left: 0;
}
.middle-wave-bg-0 {
  @extend .img;
  z-index: -2;
  opacity: .1;
  margin-top: -50%;
  width: 100%;
  left: -8%;
}
.grey-square-angle-0 {
  @extend .img;
}

