@import "../../../../styles/variables";

.ServiceItemLayout {
  width: 30%;
  text-align: center;
  color: $font-color-white;
  padding: 2em;
  box-sizing: border-box;
  z-index: 1;
  span{
    font-size: 2.5em;
    color: $font-color-yellow;
  }
  h3 {
    font-size: 1.1em;
    font-weight: $font-weight-semi-bold;
    margin: 25px 0;
  }
  p {
    font-size: .9em;
    font-weight: $font-weight-semi-bold;
    line-height: 1.389;
  }
  &:hover {
    background-color: $color-secondary;
    color: $font-color-black;
    & > span{
      color: $font-color-black;
    }
  }
}

@media screen and (max-width: $screen-l) {
  .ServiceItemLayout {
    padding: 1em;
  }
}

@media screen and (max-width: $screen-m) {
  .ServiceItemLayout {
    width: 500px;
  }
}

@media screen and (max-width: $screen-s) {
  .ServiceItemLayout {
    width: 300px;
    margin: 1em auto;
  }
}
