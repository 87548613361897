@import "../../../styles/variables";

.CompanySegment {
  z-index: 1;
  .about {
    display: flex;
    margin-top: 10em;
    justify-content: space-between;
  }
  .statement {
    text-align: center;
    margin-top: 6em;
    margin-bottom: 1em;
    h2 {
      font-size: 2.75em;
      line-height: 1.39;
      font-weight: 500;
      display: inline;
      mark {
        background-color: transparent;
        word-spacing: 0;
        font-weight: 800;
      }
    }
  }
}

@media screen and (max-width: $screen-m) {
  .CompanySegment {
    .about {
      margin-top: 3em;
      flex-flow: column;
      justify-content: center;
    }

    .statement h2 {
      font-size: 1.5em;
    }
  }
}

@media screen and (max-width: $screen-s) {
  .CompanySegment {
    .statement h2 {
      font-size: 1.25em;
    }
  }
}

