@import "../../../styles/variables";

.MainSegment {
  color: $font-color-white;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  height: 800px;
  &>div {
    width: 50%;
    z-index: 1;
  }
  &::before {
    content: '';
    width: 100%;
    height: 130%;
    position: absolute;
    top: -30%;
    left: 0;
    background-image: linear-gradient(to right, #3c37b7, #5a54e0, #54befc, #54befc);
    z-index: -1;
    transform: skewY(-7deg);
  }
  .top-right-wave {
    position: absolute;
    opacity: .1;
    right: -90%;
    top: -13em;
    width: 200%;
    z-index: 0;
  }
}

@media screen and (max-width: $screen-m) {
  .MainSegment {
    height: 650px;
    &>div {
      width: 100%;
    }
  }
}

@media screen and (max-width: $screen-s) {
  .MainSegment {
    height: 600px;
  }
}
